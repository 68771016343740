@use "../../../common/css/base" as base;

.tab-div {
  flex: 1;
}

.tab-div:hover {
  background-color: #b8b8b8 !important;
}

.tab-container-common {
  display: flex;
  z-index: 999;
  height: var(--custom-tab-height) !important;
  gap: var(--custom-tab-gap) !important;
  width: -webkit-fill-available;
  position: relative;
  border: 0.147px solid base.$ap-header-gray;
  // -ms-overflow-style: none; /* IE and Edge */
  // scrollbar-width: none; /* Firefox */
}

// .tab-container-common::-webkit-scrollbar {
//   display: none;
// }

.start-tab-common {
  background: #dcdcdc;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 0px 0px 0px 5px;
  width: calc(var(--custom-tab-width) * 0.5) !important;
  height: var(--custom-tab-height) !important;
  gap: 25% !important;
  flex: 0 0 auto;
}

.tab-holder {
  display: flex;
  overflow-x: scroll;
  flex: 0 1 auto;
  gap: var(--custom-tab-gap) !important;
}

.tab-holder::-webkit-scrollbar {
  display: none;
  width: 0px;
}

.filling-tab {
  background: #dcdcdc;
  display: flex;
  flex: 1;
  border-radius: 0px 0px 5px 0px;
}

.navigator-tab {
  @extend .tab-container-common;
  z-index: 9999;
  position: absolute;
  right: 0vw;
  top: 0;
  gap: 10px !important;
  justify-content: center;
  align-items: center;
  width: var(--custom-nav-width) !important;
  background-color: base.$ap-light-gray !important;
  border-color: base.$ap-header-gray !important;
  background: linear-gradient(
    to right,
    base.$ap-light-gray,
    transparent
  ); /* Create a gradient background */
  box-shadow: 0px 0 5px rgba(0, 0, 0, 0.7);
}

.tab {
  cursor: pointer;
  background: #dcdcdc;
  width: var(--custom-tab-width) !important;
  height: var(--custom-tab-height) !important;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: var(--custom-font-size);
  line-height: 1rem;
  padding: 0rem 0.625rem;
  /* identical to box height, or 140% */

  display: flex;
  align-items: center;
  justify-content: space-between;

  /* AP black */

  color: #404040;

  /* Inside auto layout */

  flex: none;
  order: 0;
  flex-grow: 0;
}
