@use "../../../common/css/base" as base;

.filter-text {
  display: flex;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  color: base.$ap-white;
  text-align: right;
  font-family: Poppins;
  flex-direction: column;
  justify-content: center;
}

#upload-grid {
  * > .ag-root-wrapper {
    border-radius: 0px;
  }
  * > .ag-header-viewport {
    background-color: base.$ap-header-gray;
  }
  * > .ag-body-vertical-scroll {
    background-color: base.$ap-dialog-black;
  }
  * > .ag-header-cell-comp-wrapper {
    border-radius: 0px;
  }
  * > .ag-header-cell {
    flex-direction: column;
  }
  * > .ag-cell {
    padding: var(--custom-upload-dialog-cell-padding);
  }
  * > .ag-cell:last-child {
    justify-content: flex-end;
  }
  * > .ag-body-vertical-scroll {
    --ag-background-color: #ffff55;
  }
}

.close-button {
  display: flex;
  width: var(--custom-close-width);
  height: var(--custom-close-height);
  padding: var(--custom-close-padding);
  justify-content: center;
  align-items: center;
  gap: var(--custom-close-gap);
  flex-shrink: 0;
}

.close-button-text {
  color: base.$ap-close-button-black;
  text-align: center;
  font-family: base.$font;
  font-size: var(--custom-close-gap);
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}
