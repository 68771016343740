.menu-status-text {
  padding: 0.188rem;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 0.75rem;
  line-height: 1.125rem;
  display: flex;
  align-items: center;
}

.menu-market-grid {
  grid-template-columns: repeat(3, 1fr);
  overflow-y: auto;
}
