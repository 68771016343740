html {
  width: 100%;
  height: 100%;
}

body {
  width: 100%;
  height: 100%;
  margin: 0;
  font-family: "Poppins" !important;
  // font-size: 18px;
  /* font-family: -apple-system, BlinkMacSystemFont, "Poppins", "Segoe UI",
    "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
    "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; */
}

.disable-pointer-events {
  pointer-events: none;
}

.visibilty-not-hiddent {
  visibility: visible;
}

.visible-hidden {
  visibility: hidden;
}

.bold-text {
  font-weight: bold !important;
}

a,
button,
img {
  cursor: pointer;
}

input {
  padding: 5px !important;
}

.button-orange {
  background: #e99210;
  border-color: #e99210;
}

.button-grey {
  background: #404040;
  border-color: #404040;
}

.react-toggle {
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
  touch-action: pan-x;

  display: inline-block;
  position: relative;
  cursor: pointer;
  background-color: transparent;
  border: 0;
  padding: 0;

  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  -webkit-tap-highlight-color: transparent;
}

.react-toggle-screenreader-only {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}

.react-toggle--disabled {
  cursor: not-allowed;
  opacity: 0.5;
  -webkit-transition: opacity 0.25s;
  transition: opacity 0.25s;
}

.react-toggle-track {
  width: 30px !important;
  height: 15px !important;
  padding: 0;
  border-radius: 30px;
  background-color: #4d4d4d;
  -webkit-transition: all 0.2s ease;
  -moz-transition: all 0.2s ease;
  transition: all 0.2s ease;
}

.react-toggle:hover:not(.react-toggle--disabled) .react-toggle-track {
  background-color: #828282;
}

.react-toggle--checked .react-toggle-track {
  background-color: #e99210;
}

.react-toggle--checked:hover:not(.react-toggle--disabled) .react-toggle-track {
  background-color: #e99210;
}

.react-toggle-track-check {
  position: absolute;
  width: 14px !important;
  height: 10px !important;
  top: 0px;
  bottom: 0px;
  margin-top: auto;
  margin-bottom: auto;
  line-height: 0;
  left: 8px;
  opacity: 0;
  -webkit-transition: opacity 0.25s ease;
  -moz-transition: opacity 0.25s ease;
  transition: opacity 0.25s ease;
}

.react-toggle--checked .react-toggle-track-check {
  opacity: 1;
  -webkit-transition: opacity 0.25s ease;
  -moz-transition: opacity 0.25s ease;
  transition: opacity 0.25s ease;
}

.react-toggle-track-x {
  position: absolute;
  width: 10px !important;
  height: 10px !important;
  top: 0px;
  bottom: 0px;
  margin-top: auto;
  margin-bottom: auto;
  line-height: 0;
  right: 10px;
  opacity: 1;
  -webkit-transition: opacity 0.25s ease;
  -moz-transition: opacity 0.25s ease;
  transition: opacity 0.25s ease;
}

.react-toggle--checked .react-toggle-track-x {
  opacity: 0;
}

.react-toggle-thumb {
  transition: all 0.5s cubic-bezier(0.23, 1, 0.32, 1) 0ms;
  position: absolute;
  top: 2.5px;
  left: 4px;
  width: 10px !important;
  height: 10px !important;
  border: 1px solid #b8b8b8;
  border-radius: 50%;
  background-color: #b8b8b8;

  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;

  -webkit-transition: all 0.25s ease;
  -moz-transition: all 0.25s ease;
  transition: all 0.25s ease;
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
}

.react-toggle--checked .react-toggle-thumb {
  left: 16px;
  border-color: #e99210;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.5);
  transform: rotate(180deg);
  background-color: #e99210;
}

.react-toggle:hover:not(.react-toggle--disabled) .react-toggle-thumb {
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.5);
}

::-webkit-scrollbar {
  margin-left: 10px;
  width: 6px;
  height: 6px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #dcdcdc;
  border-radius: 6px;
  transform: rotate(90deg);
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #b8b8b8;
  border-radius: 6px;
  transform: rotate(90deg);
}

.hide {
  display: none !important;
}
