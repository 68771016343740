@use "../../../../common/css/base" as base;
.value-edited {
  background-color: #b6dea8 !important;
}

.value {
  font-family: base.$font;
  font-size: var(--custom-font-size);
  font-style: normal;
  font-weight: 400;
  padding: 0.3125em;
  line-height: 0.875em;
  color: base.$ap-black;
  height: 1.25em;
  display: flex;
  align-items: center;
}

.value-selected {
  background: base.$ap-medium-orange;
}

.value-not-selected {
  background: base.$ap-medium-gray;
}
