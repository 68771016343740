@use "../../../../common/css/base" as base;

.uploader-header-text {
  font-family: base.$font;
  color: base.$ap-black;
  font-style: normal;
  font-weight: 700;
  font-size: var(--custom-upload-dialog-grid-header-font-size);
  line-height: var(--custom-upload-dialog-grid-header-line-height);
}
