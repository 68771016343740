@use "../../../../common/css/base" as base;

.text {
  font-family: base.$font;
  font-style: normal;
}

.document-name {
  @extend .text;
  font-weight: 400;
  font-size: var(--custom-document-name-font-size);
  line-height: var(--custom-document-name-line-height);
}

.done-font-color {
  color: base.$ap-black;
}

.in-progress-font-color {
  color: base.$ap-medium-dark-gray;
}

.bold-name-text {
  @extend .text;
  font-weight: 700;
  font-size: var(--custom-document-name-other-font-size);
  line-height: var(--custom-document-name-other-line-height);
}

.light-name-text {
  @extend .text;
  font-weight: 400;
  font-size: var(--custom-document-name-other-font-size);
  line-height: var(--custom-document-name-other-line-height);
}

.uploaded-text {
  @extend .text;
  font-weight: 400;
  text-align: right;
  line-height: var(--custom-upload-dialog-line-height);
}

.uploaded-date {
  @extend.uploaded-text;
  font-size: var(--custom-upload-dialog-date-font-size);
}

.uploaded-time {
  @extend.uploaded-text;
  font-size: var(--custom-upload-dialog-time-font-size);
}

.uploader-date-time {
  display: flex;
  flex-direction: column;
  flex: 1 0;
  justify-content: flex-end;
  gap: var(--custom-upload-dialog-datetime-gap);
}
