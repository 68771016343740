@use "../../../common/css/base" as base;

.active-lease {
  color: base.$ap-dark-orange;
}
.in-lease {
  color: base.$ap-white;
}
.in-lease:hover {
  color: base.$ap-black;
}
.dead-deal {
  color: base.$ap-medium-gray;
}
