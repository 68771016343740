@use "../../../common/css/base" as base;
@use "~ag-grid-community/styles" as ag;

// .ag-row-selected {
//   display: table !important;
//   .ag-cell {
//     display: table-row !important;
//   }
// }

.img-no-shirnk {
  flex-shrink: 0;
}

.author-landlord-color {
  color: base.$ap-deep-blue !important;
}

.author-tenant-color {
  color: base.$ap-green !important;
}

.author-no-color {
  color: base.$ap-medium-dark-gray !important;
  font-style: italic !important;
  font-size: var(--custom-dashboard-author-font-size) !important;
}

.loi-status-font-weight-parent {
  font-weight: 700 !important;
}

.loi-status-font-weight-child {
  font-weight: 600 !important;
}

.status-active-color {
  color: base.$ap-dark-orange !important;
}

.status-in-lease-color {
  color: base.$ap-black !important;
}

.status-dead-deal-color {
  color: base.$ap-medium-gray !important;
}

// .ag-pinned-left-cols-container .ag-cell:nth-child(2n-1),

.ag-pinned-right-cols-container .ag-cell:last-child {
  justify-content: center !important;
}

.ag-header-cell:last-child .ag-header-cell-comp-wrapper div {
  justify-content: center !important;
}

.ag-root-wrapper {
  border-radius: 0.55044rem 0rem 0rem 0rem;
  border: #040404;
}

.header-border-common {
  content: "";
  position: relative;
  display: inline-block;
  width: 100%;
  height: var(--custom-page-header-border-element-height) !important;
  z-index: 2;
}

.ag-pinned-left-header {
  [role="columnheader"]::after {
    @extend .header-border-common;
    border-top: var(--custom-page-header-border-thickness) solid base.$ap-black;
    background-color: base.$ap-dark-orange !important;
  }
}

.ag-header-viewport {
  [role="columnheader"]::after {
    @extend .header-border-common;
    border-top: var(--custom-page-header-border-thickness) solid base.$ap-black;
    background-color: base.$ap-black !important;
  }
}

.ag-pinned-right-header {
  [aria-colindex="26"]::after,
  [aria-colindex="27"]::after {
    @extend .header-border-common;
    border-top: var(--custom-page-header-border-thickness) solid base.$ap-black;
    background-color: base.$ap-dark-orange !important;
  }
  [aria-colindex="28"]::after {
    visibility: hidden;
  }
}

.ag-header-row {
  [aria-colindex="27"]::after {
    background-color: #4e4d4d !important;
  }
}

[aria-colindex="3"]::after {
  background-color: transparent !important;
}

.ag-header {
  background-color: #4e4d4d;
}

.ag-cell-wrapper {
  flex: 1;
}

.ag-horizontal-left-spacer,
.ag-horizontal-right-spacer {
  display: inline-table !important;
}

.ag-header-cell {
  border-right: var(--custom-page-header-border-thickness) solid base.$ap-black;
  font-size: var(--custom-font-size) !important;
  padding: 0px !important;
}

.ag-cell {
  font-size: var(--custom-font-size) !important;
}

.ag-header-cell-comp-wrapper {
  align-items: center !important;
  width: 100%;
}

.ag-cell-wrap-text {
  word-break: break-word !important;
}

.ag-pinned-left-header,
.ag-pinned-left-cols-container {
  box-shadow: 4px 0px 7px 0px rgba(0, 0, 0, 0.25);
  z-index: 10;
}

.ag-pinned-right-header,
.ag-pinned-right-cols-container {
  box-shadow: -4px 0px 7px 0px rgba(0, 0, 0, 0.25);
  z-index: 10;

  * > [aria-label="display component"],
  [aria-label="textarea component"] {
    height: inherit !important;
  }
}

.ag-cell-focus {
  border: solid 0.016rem #b8b8b8 !important;
}

.ag-header-viewport .ag-header-active {
  background-color: #dcdcdc !important;
}

.market-column {
  background-color: #f2f2f2;
}

.pinned-column-right-shadow {
  box-shadow: 4px 0px 7px 0px rgba(0, 0, 0, 0.25);
}

.setting-column-color {
  background-color: #4e4d4d !important;
  border-right: #4e4d4d !important;
}

.normal-column-color {
  flex-direction: column;
  background-color: #dcdcdc;
}

.pinned-column-cell-color {
  background-color: #fdf3dc;
}

.selected-row-color {
  background-color: #fad47f;
}

.pinned-column-header {
  flex-direction: column;
  background-color: #ffb000 !important;
}

.ag-cell-font-common {
  display: flex;
  color: base.$ap-black;
  font-family: base.$font;
  font-style: normal;
  line-height: normal;
  justify-content: flex-start !important;
}

.header-text-align {
  flex: 1 1 0% !important;
  justify-content: start !important;
  align-items: baseline !important;
}

.header-no-padding-margin {
  padding: 0px !important;
  margin: 0px !important;
}

.loi-status-font-color {
  display: flex;
  font-family: base.$font;
  font-style: normal;
  line-height: normal;
  justify-content: flex-start !important;
  color: #2e2e2e !important;
}

@media screen and (max-width: 1365px) {
  .ag-theme-alpine {
    --ag-border-color: #404040;
    --ag-borders-critical: #404040;
    --ag-secondary-border-color: #404040;

    // row styling
    --ag-row-border-style: solid;
    --ag-row-border-width: 0.294px;
    --ag-row-border-color: #404040;
    --ag-selected-row-background-color: #fad47f;

    --ag-header-column-separator-display: block;

    // cell stlying
    --ag-cell-horizontal-border: solid 0.294px #404040;
    // --ag-header-cell-hover-background-color: #dcdcdc;
  }

  .ag-cell[aria-colindex="27"] {
    border-right: 2px solid black !important;
  }

  .ag-body-horizontal-scroll {
    padding-top: 8px;
    padding-bottom: 8px;
    background-color: #f2f2f2;
    align-items: center !important;
  }

  .ag-body-horizontal-scroll-viewport {
    height: 4px !important;
    max-height: 4px !important;
    min-height: 4px !important;
  }

  .ag-body-vertical-scroll {
    margin: 5px 8px 0px 8px;
  }

  .ag-pinned-right-header {
    margin-right: 16.32px;
  }

  .ag-cell {
    display: flex;
    align-items: center;
    flex-shrink: 0;
    justify-content: flex-start;
    padding: 10px !important;
    text-overflow: clip;
  }

  .header-text {
    color: base.$ap-black;
    // font-size: --custom-font-size;
    font-weight: 700;
    line-height: 16.44px !important;
    flex-direction: column;
  }

  .ag-header-cell-comp-wrapper {
    padding: 10px;
    align-items: center !important;
    width: 100%;
    flex: 1;
  }

  .notes-values-text {
    // font-size: --custom-font-size;
    font-weight: 400;
    line-height: 16.44px;
    align-items: center;
    gap: 5px !important;
  }

  .field-values-text {
    color: base.$ap-black;
    // font-size: --custom-font-size;
    font-weight: 400;
  }

  .author-values-text {
    // font-size: 11.743px;
    font-weight: 400;
  }

  .market-values-text {
    // font-size: 11.743px;
    font-weight: 400;
  }
}

@media screen and (min-width: 1366px) and (max-width: 1919px) {
  .ag-theme-alpine {
    --ag-border-color: #404040;
    --ag-borders-critical-color: #404040;
    --ag-secondary-border-color: #404040;

    // row styling
    --ag-row-border-style: solid;
    --ag-row-border-width: 0.313px;
    --ag-row-border-color: #404040;
    --ag-selected-row-background-color: #fad47f;

    --ag-header-column-separator-display: block;

    // cell stlying
    --ag-cell-horizontal-border: solid 0.313px #404040;
    // --ag-header-cell-hover-background-color: #dcdcdc;
  }
  .ag-body-vertical-scroll {
    margin: 5.34px 8.54px 0px 8.54px;
  }

  .ag-pinned-right-header {
    margin-right: 16.32px;
  }

  .ag-body-horizontal-scroll {
    padding-top: 8.54px;
    padding-bottom: 8.54px;
    background-color: #f2f2f2;
    align-items: center !important;
  }

  .ag-body-horizontal-scroll-viewport {
    height: 4.27px !important;
    max-height: 4.27px !important;
    min-height: 4.27px !important;
  }

  .ag-cell {
    display: flex;
    align-items: center;
    flex-shrink: 0;
    justify-content: flex-start;
    gap: 10.672px;
    padding: 10.672px !important;
    text-overflow: clip;
  }

  .field-values-text {
    // font-size: 12.532px;
    font-weight: 400;
  }

  .author-values-text {
    // font-size: 12.532px;
    font-weight: 600;
  }

  .market-values-text {
    // font-size: 12.532px;
    font-weight: 400;
  }

  .header-text {
    color: base.$ap-black;
    // font-size: var(--custom-font-size, 12.532px);
    font-weight: 700;
    line-height: 17.545px !important;
    flex-direction: column;
  }

  .ag-header-cell-comp-wrapper {
    padding: 10.7px;
    align-items: center !important;
    width: 100%;
    flex: 1;
  }
  .notes-values-text {
    // font-size: 12.532px;
    font-weight: 400;
    line-height: 17.545px;
    align-items: center;
    gap: 5px !important;
  }
}

@media screen and (min-width: 1920px) and (max-width: 2047px) {
  .ag-theme-alpine {
    --ag-border-color: #404040;
    --ag-borders-critical: #404040;
    --ag-secondary-border-color: #404040;

    // row styling
    --ag-row-border-style: solid;
    --ag-row-border-width: 0.44px;
    --ag-row-border-color: #404040;
    --ag-selected-row-background-color: #fad47f;

    --ag-header-column-separator-display: block;

    // cell stlying
    --ag-cell-horizontal-border: solid 0.44px #404040;
    // --ag-header-cell-hover-background-color: #dcdcdc;
  }

  .ag-body-vertical-scroll {
    margin: 7.5px 12px 0px 12px;
  }

  .ag-pinned-right-header {
    margin-right: 1.5rem;
  }

  .ag-body-horizontal-scroll {
    padding-top: 12px;
    padding-bottom: 12px;
    background-color: #f2f2f2;
    align-items: center !important;
  }

  .ag-body-horizontal-scroll-viewport {
    height: 6px !important;
    max-height: 6px !important;
    min-height: 6px !important;
  }

  .ag-cell {
    display: flex;
    align-items: center;
    flex-shrink: 0;
    justify-content: flex-start;
    padding: 15px !important;
    text-overflow: clip;
  }

  .ag-row-no-animation .ag-row {
    transition: width 5s !important;
  }

  .field-values-text {
    // font-size: 17.615px;
    font-weight: 400;
  }

  .author-values-text {
    // font-size: 17.615px;
    font-weight: 600;
  }

  .market-values-text {
    // font-size: 17.615px;
    font-weight: 400;
  }

  .notes-values-text {
    // font-size: 17.61px;
    font-weight: 400;
    line-height: 24.661px;
    align-items: center;
    gap: 5px !important;
  }

  .header-text {
    color: base.$ap-black;
    // font-size: 17.615px;
    font-weight: 700;
    line-height: 24.661px !important;
    flex-direction: column;
  }

  .ag-header-cell-comp-wrapper {
    padding: 15px;
    align-items: center !important;
    width: 100%;
    flex: 1;
  }
}

@media screen and (min-width: 2048px) {
  .ag-theme-alpine {
    --ag-border-color: #404040;
    --ag-borders-critical: #404040;
    --ag-secondary-border-color: #404040;

    // row styling
    --ag-row-border-style: solid;
    --ag-row-border-width: 0.44px;
    --ag-row-border-color: #404040;
    --ag-selected-row-background-color: #fad47f;

    --ag-header-column-separator-display: block;

    // cell stlying
    --ag-cell-horizontal-border: solid 0.44px #404040;
    // --ag-header-cell-hover-background-color: #dcdcdc;
  }

  .ag-body-vertical-scroll {
    margin: 7px 12px 0px 12px;
  }

  .ag-pinned-right-header {
    margin-right: 1.5rem;
  }

  .ag-body-horizontal-scroll {
    padding-top: 12px;
    padding-bottom: 12px;
    background-color: #f2f2f2;
    align-items: center !important;
  }

  .ag-body-horizontal-scroll-viewport {
    height: 6px !important;
    max-height: 6px !important;
    min-height: 6px !important;
  }

  .ag-cell {
    display: flex;
    align-items: center;
    flex-shrink: 0;
    justify-content: flex-start;
    padding: 15px !important;
    text-overflow: clip;
  }

  .ag-row-no-animation .ag-row {
    transition: width 5s !important;
  }

  .field-values-text {
    // font-size: 17.615px;
    font-weight: 400;
    line-height: 24.661px !important;
  }

  .author-values-text {
    // font-size: 17.615px;
    font-weight: 600;
  }

  .market-values-text {
    // font-size: 17.615px;
    font-weight: 400;
  }

  .notes-values-text {
    // font-size: 17.61px;
    font-weight: 400;
    line-height: 24.661px;
    align-items: center;
    gap: 5px !important;
  }

  .header-text {
    color: base.$ap-black;
    // font-size: 17.615px;
    font-weight: 700;
    line-height: 24.661px !important;
    flex-direction: column;
  }

  .ag-header-cell-comp-wrapper {
    padding: 15px;
    align-items: center !important;
    width: 100%;
    flex: 1;
  }
}

.contexify_theme-menu {
  /* --contexify-menu-bgColor: #404040; */
  --contexify-zIndex: 9999;
  --contexify-menu-minWidth: 10%;
  --contexify-menu-padding: 0px;
  --contexify-menu-radius: 0px;
  --contexify-activeItem-radius: 0px;
  --contexify-xIndex: 666;
  --contexify-menu-bgColor: #404040;
  --contexify-separator-color: rgba(0, 0, 0, 0.2);
  --contexify-item-color: #ffffff;
  --contexify-activeItem-color: #fff;
  --contexify-activeItem-bgColor: #ffb000;
  --contexify-rightSlot-color: #6f6e77;
  --contexify-activeRightSlot-color: #fff;
  --contexify-arrow-color: #6f6e77;
  --contexify-activeArrow-color: #fff;

  overflow-y: auto;
  max-height: 30vh;

  .contexify__item__content {
    font-family: base.$font;
    font-style: normal;
    font-weight: 200;
    font-size: 5px !important;
    line-height: var(--custom-settings-line-height);
    display: flex;
    align-items: center;

    /* AP White */

    color: #ffffff;

    /* Inside auto layout */

    flex: none;
    order: 1;
    flex-grow: 0;
  }
}
