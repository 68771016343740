@use "../../../common/css/base" as base;

.value-label {
  font-family: base.$font;
  font-style: normal;
  font-size: var(--custom-edit-font-size);
  line-height: var(--custom-edit-line-height);
  font-weight: var(--custom-edit-header-font-weight);
  display: flex;
  align-items: center;
  color: #404040;
  cursor: pointer;
}

.value-pair {
  display: flex;
  flex-direction: column;
  padding: 0.3125rem;
  gap: 0.625rem;
}

.value-action {
  flex: 1;
  display: flex;
  gap: 0.25em;
  justify-content: end;
}

.value-container {
  display: flex;
  flex-direction: column;
  overflow-x: auto; /* gap: 1.25em; */
  padding-right: 0.625rem;
}

.abstractor {
  display: flex;
  width: 100%;
  flex-direction: column;
  background: #f2f2f2;

  box-shadow: 0px 4px 25px #404040;
  border-radius: 0px;
  margin: 1%;
}

.heading-font {
  font-family: base.$font;
  font-weight: var(--custom-edit-header-font-weight);
  font-size: var(--custom-edit-header-tag-font-size);
  line-height: var(--custom-edit-header-tag-line-height);
  font-style: normal;
  display: flex;
  align-items: center;
}

.abstractor-heading {
  @extend .heading-font;
  color: #ffffff;
}

.abstractor-file-name {
  @extend .heading-font;
  color: #ffb000;
}

.abstractor-header {
  display: flex;
  /* flex: 1; */
  background: #2e2e2e;
  box-shadow: 0px 9.174762725830078px 57.342262268066406px 0px #404040;
  border-radius: 0px;
  padding: 1.5%;
}

.absractor-values {
  /* AP light grey */
  display: flex;
  flex-direction: column;
  flex: 1;
  background: #f2f2f2;
  box-shadow: 0px 4px 25px #404040;
  border-radius: 0px;
  max-width: 45% !important;
  padding: 0.625rem;
}

.action-container {
  display: flex;
  justify-content: end;
  gap: 10px;
  margin: 10px;
}

.abstractor-divider {
  display: flex;
  height: 5%;
}

.abstractor-html {
  display: flex;
  flex: 1;
}

.absractor-divide {
  display: flex;
  flex-direction: row;
  overflow: hidden;
}

iframe {
  display: flex;
  width: 55% !important;
  border: 0px;
  // padding: 0.313rem;
  /*width: 600px;
  height: 550px; */
  -moz-transform: scale(1, 1);
  -webkit-transform: scale(1, 1);
  -o-transform: scale(1, 1);
  -ms-transform: scale(1, 1);
  transform: scale(1, 1);
  -moz-transform-origin: top left;
  -webkit-transform-origin: top left;
  -o-transform-origin: top left;
  -ms-transform-origin: top left;
  transform-origin: top left;
}

.html-loader {
  width: 100%;
  height: 100%;
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
}
